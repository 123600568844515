<script lang="ts">
    import { onMount } from 'svelte';
    import axios from 'axios';

    export let vapidPublicKey;
    export let errors = {};
    let subscriptionStatus = 'Not subscribed';
    let error = null;
    let serviceWorkerRegistration = null;

    console.log('Component script loaded');

    function urlBase64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    onMount(async () => {
        const csrfToken = document.querySelector('meta[name="csrf-token"]')?.content;
        if (csrfToken) {
            axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
        } else {
            console.error('CSRF token not found');
        }

        if ('serviceWorker' in navigator) {
            try {
                const registration = await navigator.serviceWorker.register('/service-worker.js');
                console.log('Service Worker registered:', registration);

                if (!registration.active) {
                    console.log('Service worker not active yet, waiting...');
                    await new Promise((resolve) => {
                        registration.addEventListener('updatefound', () => {
                            const newWorker = registration.installing;
                            newWorker.addEventListener('statechange', () => {
                                if (newWorker.state === 'activated') resolve();
                            });
                        });
                    });
                }
                console.log('Service worker active now');
                serviceWorkerRegistration = registration;

                const existingSubscription = await registration.pushManager.getSubscription();
                if (existingSubscription) {
                    subscriptionStatus = 'Already subscribed';
                    console.log('Existing subscription:', existingSubscription);
                }
            } catch (err) {
                console.error('Service Worker registration failed:', err);
                error = 'Failed to register service worker';
            }
        } else {
            error = 'Push notifications not supported';
        }
    });

    async function subscribeToPush() {
        console.log('Button clicked');
        try {
            if (!serviceWorkerRegistration) {
                console.log('No registration yet, waiting for service worker');
                serviceWorkerRegistration = await navigator.serviceWorker.ready;
            }
            console.log('Using registration:', serviceWorkerRegistration.active ? 'Active' : 'Not active');

            console.log('Notification permission:', Notification.permission);
            if (Notification.permission === 'denied') {
                error = 'Notification permission denied. Please enable it in browser settings.';
                return;
            }

            console.log('Preparing VAPID key:', vapidPublicKey);
            const vapidKeyArray = urlBase64ToUint8Array(vapidPublicKey);
            console.log('VAPID key converted to Uint8Array, length:', vapidKeyArray.length);

            console.log('Attempting subscription...');
            const subscription = await serviceWorkerRegistration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: vapidKeyArray,
            });
            console.log('Subscription created:', subscription);

            console.log('Sending subscription to backend');
            await axios.post('/save-subscription', { subscription }).then((response) => {
                console.log('Backend response:', response.data);
                subscriptionStatus = 'Subscribed successfully';
            });
        } catch (err) {
            error = 'Subscription failed: ' + err.message;
            console.error('Subscription error:', err);
        }
    }
</script>

<div>
    <h1>Push Notifications</h1>
    <p>Status: {subscriptionStatus}</p>
    {#if error}
        <p class="error">{error}</p>
    {/if}
    {#if errors.subscription}
        <p class="error">{errors.subscription}</p>
    {/if}
    <button
        on:click="{subscribeToPush}"
        disabled="{subscriptionStatus === 'Subscribed successfully' || subscriptionStatus === 'Already subscribed'}"
    >
        Subscribe to Notifications
    </button>
</div>

<style>
    .error {
        color: red;
    }
</style>
